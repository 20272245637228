import type { SiteConfig } from '@/_types'

export const siteConfig: SiteConfig = {
  name: 'viidoo',
  host: 'viidoo.space',

  languages: ['en', 'de'],
  i18nLocalesModules: {
    en: () => import('../locales/en.json'),
    de: () => import('../locales/de.json'),
  },

  siteTitle: 'Viidoo Mac App',
  baseUrl: 'https://how.viidoo.it',
  iconUrl: 'https://how.viidoo.it/icon-1024.png',

  // posts: () => import('./_posts/meta.json'),
  // postsPath: 'blog',

  plausibleId: 'viidoo.space',
  plausibleAppId: 'app.viidoo.space',

  repliesProductName: 'Viidoo',

  // Activate social media sharing
  shareIntro: 'Found on "Viidoo - Document management for finances that learns!"',

  supportEmail: 'support@viidoo.it',

  productReleases: () => import('./_macos/releases.json'),
}
